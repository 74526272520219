import React from "react";
import parse from "html-react-parser";
import { AiOutlineClose } from "react-icons/ai";

const PopUp = ({ activitypopup, setActivitypopup, post, i }) => {
  return (
    <dialog className="activity-card-popup" open={activitypopup}>
      <div className="flex-wrapper">
        <div className="popup-content">
          <div className="popup-header">
            <div className="header-info">
              <AiOutlineClose
                className="close"
                onClick={() => setActivitypopup(false)}
              />
              {post.acf.image && (
                <img src={post.acf.image?.url} alt={post.acf.image?.url} />
              )}
            </div>
          </div>
          <div className="popup-main-text">
            <h2 id="research-pop-title">{parse(post.acf.title)}</h2>
            {/* <p className="popup-date-author">by {post.acf.auther}</p>
           <h2 className="research-title">{post.acf?.title}</h2> */}
            {/* <p className="research-author">Written by {post.acf?.author}</p> */}
            <p className="research-description">
              {parse(post.acf?.projectdescription)}
            </p>
            <ol className="research-objectives">
              <li
                style={
                  post.acf?.objectivesone
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                {parse(post.acf?.objectivesone)}
              </li>
              <br></br>
              <li
                style={
                  post.acf?.objectivestwo
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                {parse(post.acf?.objectivestwo)}
              </li>
              <br></br>
              <li
                style={
                  post.acf?.objectivesthree
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                {parse(post.acf?.objectivesthree)}
              </li>
              <br></br>
              <li
                style={
                  post.acf?.objectivesfour
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                {parse(post.acf?.objectivesfour)}
              </li>
              <br></br>
              <li
                style={
                  post.acf?.objectivesfive
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                {parse(post.acf?.objectivesfive)}
              </li>
            </ol>

            <h3 className="research-workplan-title">
              {parse(post.acf?.workplantitle)}
            </h3>
            <p className="research-workplan">{parse(post.acf?.workplan)}</p>
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default PopUp;
