import "./slider.css";
import "./Slider2.css";
import React, { useState, useEffect } from "react";
import Data from "./Data";
import { BsChevronLeft } from "react-icons/bs";
import { BsChevronRight } from "react-icons/bs";
import left from "../../assets/Emblem/Emblem elements/Laurels/Aktiv 1.png";
import right from "../../assets/Emblem/Emblem elements/Laurels/Aktiv 2.png";
import parse from "html-react-parser";

export default function Slider() {
  const [navigation] = useState(Data);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const lastIndex = navigation.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, navigation]);
  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 12000);
    return () => {
      clearInterval(slider);
    };
  }, [index]);
  return (
    <section className="section">
      <img className="left-l" src={left} alt="Left Laurel"></img>

      <div className="section-center">
        {navigation.map((item, indexPage) => {
          const { id, name, quote } = item;
          let position = "nextSlide";
          if (indexPage === index) {
            position = "activeSlide";
          }
          if (
            indexPage === index - 1 ||
            (index === 0 && indexPage === navigation.length - 1)
          ) {
            position = "lastSlide";
          }
          return (
            <>
              <article className={position} key={id}>
                <h4>{name}</h4>
                <p className="text">{parse(quote)}</p>
              </article>
            </>
          );
        })}
        <button className="prev" onClick={() => setIndex(index - 1)}>
          <BsChevronLeft />
        </button>
        <button className="next" onClick={() => setIndex(index + 1)}>
          <BsChevronRight />
        </button>
      </div>
      <img className="right-l" src={right} alt="Right Laurel"></img>
    </section>
  );
}
