import React, { useState, useEffect } from "react";
import FilterAuthor from "./FilterAuthor/FilterAuthor";
import "./pages.css";
import parse from "html-react-parser";

const Research = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await fetch(
        "https://www.p-citizens.gwi.uni-muenchen.de//wp-json/wp/v2/research"
      );
      const data = await response.json();
      console.log(data);
      setPosts(data);
    }
    getData();
  }, []);

  posts.sort(function (a, b) {
    if (a.acf.order < b.acf.order) {
      return -1;
    }
    if (a.acf.order > b.acf.order) {
      return 1;
    }
    return 0;
  });

  console.log(posts);

  const [overview, setOverview] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await fetch(
        "https://www.p-citizens.gwi.uni-muenchen.de//wp-json/wp/v2/projectoverview"
      );
      const data = await response.json();
      setOverview(data);
    }
    getData();
  }, []);

  const test = () => {
    let i = 0;
    posts.forEach((post) => {
      i = i + 1;
    });
    return i;
  };
  let d = test();
  console.log(d);

  let [category, setCategory] = useState("All authors");

  return (
    <>
      {overview.map((overviews) => (
        <div className="research-flex">
          <div className="research-indi">
            <h2 className="research-title">{overviews.acf.title}</h2>
            <p className="research-description">
              {parse(overviews.acf.projectdescription)}
            </p>
          </div>
        </div>
      ))}
      <section
        className="research-section"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          left: "none",
          position: "relative",
        }}
      >
        {posts.map((post, i) => (
          <FilterAuthor category={category} post={post} i={i} />
        ))}
      </section>
    </>
  );
};

export default Research;
