import React, { useState } from "react";
import ActivityPopup from "./ActivityPopup";
import parse from "html-react-parser";

const ContentCard = ({ post, category, index }) => {
  const [activitypopup, setActivitypopup] = useState(false);
  let showCard = false;
  const [cardCategories, setCardCategories] = useState(post.acf.tags);

  const tags = [];
  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

    // Convert the string to a Date object
    const parseDate = (dateStr) => {
      const parts = dateStr.split("/");
      return new Date(parts[2], parts[1] - 1, parts[0]); // year, month (0-indexed), day
    };

  // This arrangement can be altered based on how we want the date's format to appear.
  let currentDateStr = `${day}/${month}/${year}`;
  // console.log(currentDate); // "17-6-2022"

  const endDateParts = post.acf.enddate.split("-");
  const endDateStr = endDateParts[0];

  const currentDate = parseDate(currentDateStr);
  const endDate = parseDate(endDateStr);

  post.acf.tags.map((tag) => {
    tags.push(tag);
  });
  tags.shift();
  // console.log(tags);

  return (
    <>
      {cardCategories.includes(category) ? (
        <>
          <div
            className="content-card-container"
            onClick={() => setActivitypopup(true)}
          >
            <div
              style={{ display: post.acf.image === false ? "none" : "block" }}
              className="content-img"
            >
              <img src={post.acf.image?.url} alt="sdsd" />
            </div>
            <div className="content-card-info">
              {currentDate <= endDate && <p>Upcoming event:</p>}
              <h2 id="cardTitle">{parse(post.acf.cardtitle)}</h2>
              <h3>{parse(post.acf.auther)}</h3>
              <div className="categories-publishdate">
                <p id="tags">{tags + ""}</p>
                <p id="date">
                  {post.acf.startdate} {post.acf?.startdate === "" ? "" : "-"}{" "}
                  {parse(post.acf.enddate)}
                </p>
              </div>
            </div>
          </div>
          <ActivityPopup
            post={post}
            activitypopup={activitypopup}
            setActivitypopup={setActivitypopup}
          />
        </>
      ) : null}
    </>
  );
};

export default ContentCard;
