import React, { useState, useEffect } from "react";

const Filters = (props) => {
  const [OpenCategories, setOpenCategories] = useState(false);

  var width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  useEffect(() => {
    if (width > 1000) {
      setOpenCategories(true);
    }
  }, []);

  const handleOpen = () => {
    if (width < 1000) {
      setOpenCategories(!OpenCategories);
    } else {
      setOpenCategories(true);
    }
  };

  let category;

  const handleChange = (e) => {
    category = e;
    props.onSelectCategory(category);
  };

  return (
    <div className="column filters filters-container">
      <h2 onClick={handleOpen}>Sort Categories</h2>
      {OpenCategories ? (
        <form className="categories">
          <div>
            <input
              type="radio"
              id="All"
              onClick={() => handleChange("All Categories")}
              name="categories"
            ></input>
            <label for="All">All Categories</label>
          </div>

          <div>
            <input
              type="radio"
              id="Presentation"
              value="Presentations"
              onClick={() => handleChange("Presentations")}
              name="categories"
            ></input>
            <label for="Presentation">Presentations</label>
          </div>

          <div>
            <input
              type="radio"
              id="Research Diaries"
              name="categories"
              value="Research Diaries"
              onClick={() => handleChange("Research Diaries")}
            ></input>
            <label for="Research Diaries">Research Diaries</label>
          </div>

          <div>
            <input
              type="radio"
              id="Workshops"
              name="categories"
              value="Workshops"
              onClick={() => handleChange("Workshops")}
            ></input>
            <label for="Workshops">Workshops</label>
          </div>
          <div>
            <input
              type="radio"
              id="Conferences"
              name="categories"
              value="Conferences"
              onClick={() => handleChange("Conferences")}
            ></input>
            <label for="Conferences">Conferences</label>
          </div>
          <div>
            <input
              type="radio"
              id="News"
              name="categories"
              value="News"
              onClick={() => handleChange("News")}
            ></input>
            <label for="News">News</label>
          </div>

          <div>
            <input
              type="radio"
              id="Publications"
              name="categories"
              value="Publications"
              onClick={() => handleChange("Publications")}
            ></input>
            <label for="Publications">Publications</label>
          </div>
        </form>
      ) : null}
    </div>
  );
};

export default Filters;
