import React, {useState, useEffect } from 'react'
import Slider from '../frontpage/Slider.js'

const HomePage = () => {
    const [posts, setPosts] = useState([]);
    
    useEffect(() => {
        async function getData() {
            const response = await fetch("https://www.p-citizens.gwi.uni-muenchen.de//wp-json/wp/v2/slider?_embed&v=99999");
            const data = await response.json();
                        console.log(data);
            setPosts(data);
        }
        getData();
    }, []);
    return (
        <section className='front-page'>
            <Slider posts={posts} />
        </section>
    )
}

export default HomePage