import React from "react";
import { useState } from "react";
import Filters from "../components/activities/Filters";
import Content from "../components/activities/Content";
import Tweets from "../components/activities/Tweets";

import "../components/activities/activities.css";

const Activities = () => {
  let [selectedCategory, setSelectedCategory] = useState("All Categories");

  const handleLanguage = (data) => {
    setSelectedCategory(data);
  };

  return (
    <div className="filters-tweets-container">
      <Filters className="filters-tweets" onSelectCategory={handleLanguage} />
      <div className="activities-container">
        <Content className="content" category={selectedCategory} />
      </div>
      {/* <Tweets className="filters-tweets" /> */}
    </div>
  );
};

export default Activities;
