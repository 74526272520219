import React from 'react'
import { useState, useEffect } from 'react';
import parse from "html-react-parser";

const Imprint = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await fetch(
        "https://www.p-citizens.gwi.uni-muenchen.de//wp-json/wp/v2/imprint?_embed&v=99999"
      );
      const data = await response.json();
      setPosts(data);
    }
    getData();
  }, []);
  console.log(posts)
  return (
    <div className='team-section'>
          {posts.map((post) => (
      <p>
{parse(post.acf?.imprint)}
      </p>
          ))}
    </div>
  )
}

export default Imprint