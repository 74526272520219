import React from "react";
import parse from "html-react-parser";
import { AiOutlineClose } from "react-icons/ai";

const ActivityPopup = ({ activitypopup, setActivitypopup, post, i }) => {
  return (
    <dialog className="activity-card-popup" open={activitypopup}>
      <div className="flex-wrapper">
        <div className="popup-content">
          {!post.acf.image && (
            <AiOutlineClose
              className="close"
              onClick={() => setActivitypopup(false)}
            />
          )}
          {post.acf.image && (
            <div className="popup-header">
              <div className="header-info">
                <AiOutlineClose
                  className="close"
                  onClick={() => setActivitypopup(false)}
                />
              </div>
              <img src={post.acf.image?.url} alt={post.acf.image?.url} />
            </div>
          )}
          <div className="popup-main-text">
            <h2>{post.acf.cardtitle}</h2>
            <p className="popup-date-author">
              {post.acf.startdate} {post.acf?.startdate === "" ? "" : "-"}{" "}
              {parse(post.acf.enddate)}
            </p>
            {post.acf.auther && (
              <p className="popup-date-author">by {parse(post.acf.auther)}</p>
            )}
            {post.acf.link && (
              <a href={post.acf.link} className="popup-date-author">
                {post.acf.linktext ? parse(post.acf.linktext) : post.acf.link}
              </a>
            )}
            <p>{parse(post.acf.cardtext)}</p>
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default ActivityPopup;
