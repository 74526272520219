import React from "react";
import { useState, useEffect } from "react";
import Accordion from "../components/collapsible/Accordion.js";
import parse from "html-react-parser";

const Publications = () => {
  const [isActive, setIsActive] = useState(false);

  // const [isVisible, setIsVisible] = useState(0);

  // const toggle = (i) => {
  //   if (isVisible === i) {
  //     setIsVisible(0);
  //   } else setIsVisible(i);
  // };

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await fetch(
        "https://www.p-citizens.gwi.uni-muenchen.de//wp-json/wp/v2/publications?_embed&v=99999"
      );
      const data = await response.json();
      setPosts(data);
    }
    getData();
  }, []);

  posts.sort(function (a, b) {
    if (a.acf.accordiontitle < b.acf.accordiontitle) {
      return -1;
    }
    if (a.acf.accordiontitle > b.acf.accordiontitle) {
      return 1;
    }
    return 0;
  });

  let postYear = [];
  posts.forEach((post) => {
    if (postYear === []) {
      postYear.push(post.acf.publicationyear);
    }
    if (!postYear.includes(post.acf.publicationyear)) {
      postYear.push(post.acf.publicationyear);
    }
  });

  return (
    <>
      <div className="accordion-container">
        {postYear
          .sort()
          .reverse()
          .map((year, i) => (
            <>
              <h2 className="publication-year">{parse(year)}</h2>
              {posts.map((post) => {
                return (
                  postYear[i] === post.acf.publicationyear && (
                    <div className="accordion">
                      <Accordion key={i} i={i} post={post} />
                      {/* isVisible={isVisible} */}
                    </div>
                  )
                );
              })}
            </>
          ))}
      </div>
    </>
  );
};

export default Publications;
