import React, { useState } from "react";
import "./team.css";
import { BiArrowBack } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import parse from "html-react-parser";
import { HashLink } from "react-router-hash-link";

const David = ({ post, index }) => {
  const [openMember, setOpenMember] = useState("false");

  const test = () => {
    window.scrollTo({
      top: 0,
    });
    setOpenMember("true" + index);
  };

  return (
    <section className="member-section">
      {openMember === "false" ? (
        <div onClick={test} className="member-box">
          <img
            className="member-img"
            src={post.acf?.profileimage?.url}
            alt=""
          />
          <div className="member-flex">
            <h3 className="member-name">{parse(post.acf?.name)}</h3>
            <h4 className="member-title">{parse(post.acf?.title)}</h4>
          </div>
        </div>
      ) : (
        <></>
      )}
      {openMember === "true" + index && (
        <dialog open={"true" + index} className="modal-container">
          <div className="back-btn" onClick={() => setOpenMember("false")}>
            <BiArrowBack />
          </div>
          <h2 className="singleteam-name">{parse(post.acf.name)}</h2>
          <div className="singleteam-container">
            <div>
              <img
                className="singleteam-img"
                src={post.acf.profileimage?.url}
                alt={post.acf.profileimage?.alt}
              ></img>
              <p>
                  <span>{post.acf?.picturecredit}</span>
              </p>
          <div className="contact-container">
          <a className="orcid" href={`mailto:${post.acf.email}`}>
                <AiOutlineMail/>
              </a>
            <a className="orcid" href={`https://orcid.org/${post.acf?.orcidid}`}>
            <img alt="ORCID logo" src="https://info.orcid.org/wp-content/uploads/2019/11/orcid_16x16.png" width="16" height="16" />
              {post.acf?.orcidid}
            </a>
            <address>
              
            </address>
            <HashLink to={`/research/#${post.slug}`}>
                <p>
                  Go to {parse(post.acf?.name + "'s")} research
                </p>
              </HashLink>
          </div>
            </div>
            <p>
              {parse(post.acf?.bio)}
            </p>
          </div>
          <div className="top-line"></div>
        </dialog>
      )}
    </section>
  );
};

export default David;
