import "./App.css";
import Nav from "./components/nav/Nav.js";
import HomePage from "./components/nav/HomePage";
import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Publications from "./Pages/Publications.js";
import Activities from "./Pages/Activities.js";
import Contact from "./Pages/Contact";
import Team from "./Pages/Team";
import Footer from "./components/footer/Footer.js";
import Loading from "./components/frontpage/Loading";
import Research from "./Pages/Research";
import Imprint from "./Pages/Imprint";


function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <div className="App">
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Nav />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/research" element={<Research />} />
            <Route path="/publications" element={<Publications />} />
            <Route path="/activities" element={<Activities />} />
            <Route path="/team" element={<Team />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/imprint" element={<Imprint />} />
          </Routes>
          <Footer />
        </div>
      )}
    </div>
  );
}

export default App;
