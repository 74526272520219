const Data = [
  {
    id: 0,
    image: "../../assets/Logo/Names only/On transparent/P-Citizen.png",
    name: "Mission Statement",
    quote: `This is the first project to be funded by the European Research Council which makes clear that between 1780 and 1850 amateur theatre played a significant role in the shaping of European societies, cultures and identities. The project proposes the thesis that European amateur theatre in Germany, France, Britain, Sweden and Switzerland had a vital role in the formation <br> of modern concepts of citizenship.`,
  },
  {
    id: 1,
    name: "Research",
    quote:
      "The Performing Citizenship project is made up of seven complementary work packages which all have a shared interest in exploring the relationship between citizenship and amateur theatre. Five of these projects have a specific geographical and topical focus with a sub-series of research questions. Discover more about our seven work packages.",
  },
  {
    id: 2,
    name: "Activities",
    quote:
      "Want to find out what we’ve been up to? You can read our latest research blogs, check out our upcoming events, and learn more about our other activities – from delivering conference papers and guest lectures, to running workshops and visiting archives.",
  },
  {
    id: 3,
    image: "../../assets/Logo/Names only/On transparent/P-Citizen.png",
    name: "Publications",
    quote:
      "Outputs from the project will include newspaper and magazine articles, book chapters, scholarly journal articles, monographs and an edited book. Explore our publications and find out more about our research.",
  },
  {
    id: 4,
    image: "../../assets/Logo/Names only/On transparent/P-Citizen.png",
    name: "Team",
    quote:
      "Led by Prof. Meike Wagner, our team of researchers are located across Europe in France, Britain, Germany, Switzerland and Sweden. Meet our researchers and their support team.",
  },
  {
    id: 5,
    image: "../../assets/Logo/Names only/On transparent/P-Citizen.png",
    name: "Contact",
    quote:
      "Want to get in touch? Don’t hesitate. We’d love to hear from you. We’re keen to make connections with amateur theatre enthusiasts, scholars, curators, historians, and anyone else who feels that they can contribute to this project.",
  },
];

export default Data;
