import React, { useState, useEffect } from "react";
import ContentCard from "./ContentCard";

const Content = ({ category }) => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await fetch(
        "https://www.p-citizens.gwi.uni-muenchen.de//wp-json/wp/v2/activities?_embed&v=99999&per_page=40"
      );
      const data = await response.json();
      console.log(data);
      setPosts(data);
    }
    getData();
  }, []);


  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let separator = "/";

  let currentDate = `${day}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${year}`;


posts.sort(function(a, b){
  var aa = a.acf.enddate.split('/').reverse().join(),
      bb = b.acf.enddate.split('/').reverse().join();
  return aa < bb ? -1 : (aa > bb ? 1 : 0);
});

  return (
    <div className="column">

      {posts.reverse().map(
        (post, i) =>
          (currentDate >= post.acf?.enddate ||
            post.acf.activitiescardindentifyer === "Card") && (
            <ContentCard key={i} index={i} category={category} post={post} />
          )
      )}
    </div>
  );
};

export default Content;
