import React, { useEffect } from "react";
import parse from "html-react-parser";
import "./collapsible.css";
import { useState } from "react";

const Accordion = ({ id, post, i }) => {
  // set til 0 hvis det første index skal være åbent som standard
  const [isVisible, setIsVisible] = useState(true);

  const toggle = (i) => {
    if (isVisible === i) {
      setIsVisible(null);
    } else setIsVisible(i);
  };

  return (
    <>
      <div className="accordion-item" key={id} index={i}>
        <div className="accordion-title" onClick={() => toggle(i)}>
          <div>
            <span className="author">{post.acf.auther} - </span>
            {parse(post.acf.accordiontitle)}
          </div>
          <div>{isVisible === i ? "-" : "+"}</div>
        </div>
        {isVisible === i && (
          <div
            className={
              isVisible === i ? "fadein accordion-content" : "accordion-content"
            }
          >
            <div className="accordion-header">
              <h1 id="header">{parse(post.acf.publicationtitle)}</h1>
            </div>

            <div className="publcation-content">
              <div className="publication-text">
                <p>{parse(post.acf.accordionmaintext)}</p>
              <img
                src={post.acf.publicationimage?.url}
                alt={post.acf.publicationimage?.alt}
              />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Accordion;
