import React from "react";
import { useState, useEffect } from "react";
import "./contact.css";
import parse from "html-react-parser";
const Contact = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await fetch(
        "https://www.p-citizens.gwi.uni-muenchen.de//wp-json/wp/v2/contact?_embed&v=99999"
      );
      const data = await response.json();
      setPosts(data);
    }
    getData();
  }, []);

  return (
    <section className="team-section">
            {posts.map((post, i) => (
              <div style={{display: "flex", justifyContent: "space-between", gap: '2rem',}}>
        <div className="contact-flex">
          <div className="location-container">
        <h2 className="contact-title">Contact</h2>
            <div className="contact-container">
              <p>{parse(post.acf?.contact_information)}</p>
            </div>
          </div>      
        </div>
          <img src={post.acf.contact_image.url} alt="TeamPicture" className="contact-image"></img>
      </div>
            ))}
    </section>
  );
};

export default Contact;
