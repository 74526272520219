import React from 'react';

import "./pages.css";
import Member from '../components/team/Member';

const Team = () => {
    return (
        <section className='team-section'>
            <div className='top-line'></div>
            <h2 className='team-title'>Meet the team</h2>
            <div className='team-container'>
                
                    <Member />
                {/* <img className="team-img" src={team} alt="The team" /> */}
            </div>
        </section>
    )
}

export default Team