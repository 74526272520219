import React from "react";
import "./slider.css";
import emblem from "../../assets/Emblem/Full emblem/Full emblem - Monochrome/Full emblem_realbgc.png";

const Loading = () => {
  return (
    <div className="loading-container">
      <img className="emblem" src={emblem} alt="Citizenship emblem" />
    </div>
  );
};

export default Loading;
