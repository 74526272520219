import React, { useState } from "react";
import "./filter.css";
import parse from "html-react-parser";
import PopUp from "./PopUp";

const FilterAuthor = ({ post }) => {
  const [activitypopup, setActivitypopup] = useState(false);
  // let showCard = false;
  // const [cardCategories, setCardCategories] = useState(post.acf.tags);
  return (
    <>
      <div
        className="content-card-container"
        onClick={() => setActivitypopup(true)}
        id={post.slug}
      >
        {post.acf.image && (
          <div
            style={{ display: post.acf.image === false ? "none" : "block" }}
            className="content-img"
          >
            <img src={post.acf.image?.url} alt="sdsd" />
          </div>
        )}
        <div className="content-card-info research-card">
          <h2 id="cardTitle">{post.acf.title}</h2>
          <h3 className="research-card-author">{post.acf.author}</h3>
          <p className="limit-text">
            {parse(post.acf.workplan.substring(0, 200))}...
          </p>
        </div>
      </div>
      <PopUp
        post={post}
        activitypopup={activitypopup}
        setActivitypopup={setActivitypopup}
      />
    </>
  );
};

export default FilterAuthor;
